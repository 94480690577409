import defaultText from "../default-text";

const storageKey = "mdnew:state";
const defaultState = {
  value: defaultText,
};

export const storeAppState = (value) => {
  try {
    localStorage.setItem(storageKey, JSON.stringify({ value }));
  } catch {
    alert("Something went wrong");
  }
};

export const getAppStateFromStore = () => {
  let state;

  try {
    state = JSON.parse(localStorage.getItem(storageKey)) || defaultState;
  } catch {
    state = defaultState;
  }

  return state;
};
