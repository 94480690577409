import "codemirror/lib/codemirror.css";
import "codemirror/mode/markdown/markdown";

import CodeMirror from "codemirror";
import download from "./helper/download";
import { getAppStateFromStore, storeAppState } from "./helper/store";

const state = getAppStateFromStore();

function main() {
  document.querySelector(".fallback").remove();

  const editor = CodeMirror(document.getElementById("editor"), {
    mode: "markdown",
    value: state.value,
    theme: "mdnew",
    highlightFormatt: true,
    autofocus: true,
    lineWrapping: true,
    cursorBlinkRate: 0,
    viewportMargin: Infinity,
    autocorrect: true,
    spellcheck: true,
    extraKeys: {
      "Cmd-S": download,
      "Ctrl-S": download,
    },
  });

  editor.on("change", (cm) => {
    storeAppState(cm.getValue());
  });
}

document.querySelector("html").classList.add("js");
window.addEventListener("DOMContentLoaded", main);
