const getDayPrefix = (date = new Date()) => {
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`
}
export default (cm) => {
  const text = cm.getValue();
  const firstLine = cm.getDoc().getLine(0) || '';
  const defaultName = 'note';
  const startsWithHeadline = firstLine.startsWith('#')
  const safeFirstHeadline = firstLine.replace(/^\#+\s/,'')
    .replace(/[^a-z0-9]/gi, '-') 
  const filename = `${getDayPrefix()}-${ startsWithHeadline ? safeFirstHeadline || defaultName : defaultName}.md`;

  const el = document.createElement("a");
  el.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  el.setAttribute("download", filename);
  el.style.display = "none";
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};
